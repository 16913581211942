@import "../../../assets/scss/partial/variables";

.floatpic-web-location-container {
  height: calc(var(--vh, 1vh) * 100) !important;
  padding: 100px 0;
  min-height: 500px;
  overflow-y: auto;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 3;
  background-image: url('../../../../public/images/wide.png');
  background-size: cover;
  .inner-location-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .img-container {
    margin-bottom: 10px;
    img {
      width: 288px;
      height: 288px;
      @media (max-width: 991px) {
        width: 188px;
        height: 188px;
      }
    }
  }
  .location-header {
    font-size: 0.93rem;
    font-weight: 600;
    color: #30313d;
  }
  .location-list {
    list-style: none;
    margin: 30px 50px;
    margin-top: 15px;
    padding: 0;
    width: 180px;
  }
  .location-list-item {
    margin: 15px 0;
  }
  .location-list-item-link {
    color: #6d6e78;
    font-size: 0.75rem;
    text-decoration: underline;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    &:hover {
      color: #0056b3;
      text-decoration: underline;
    }
  }
  .cookie-condition-close {
    display: none;
  }
  .cookie-condition {
    position: fixed;
    bottom: 16px;
    box-shadow: 0 8px 48px rgba(0, 0, 0, 0.15);
    width: 960px;
    // height: 260px;
    padding: 16px;
    @media (max-width: 991px) {
      width: 100%;
    }
    background-color: #fff;
    .close-btn {
      z-index: 1;
      top: 6px;
      right: 0;
      margin: 10px;
      min-width: 32px;
      height: 32px;
      padding: 6px;
      font-size: 24px;
      line-height: 0;
      font-weight: lighter;
      cursor: pointer;
      text-align: center;
      border: 1px solid transparent;
      border-radius: 4px;
      opacity: 0.7;
      align-self: flex-end;
      position: absolute;
      font-size: 14px;
      color: #000000;
      background-color: $bg-white;
      &:hover {
        opacity: 1;
      }
    }
    .content-para {
      font-size: 14px;
      color: #000000;
    }
    & img {
      // width: 48px;
      // height: 48px;
      object-fit: contain;
    }
  }

  @media (max-width: 767px) {
    background-image: url('../../../../public/images/wide.png');
    background-size: cover;
    padding: 0;
  }
  @media (max-width: 450px) {
    background-image: url('../../../../public/images/tall.png');
  }
}
.single-btn {
  appearance: none !important;
  padding: 8px 32px !important;
  border-radius: 64px !important;
  cursor: pointer !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
  text-align: center !important;
  border: 0 !important;
  background-color: #1a1a1a !important;
  color: #fff !important;
}
.bottom-btn-container {
  @media (max-width: 639px) {
    flex-direction: column-reverse;
  }
}
