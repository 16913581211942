@import '../../../assets/scss/partial/variables';

.overlay::before {
	content: '';
	display: block;
	background: rgba(0, 0, 0, 0);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
}
.header-container {
	height: 40px;
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	// text-align: center;
	.menubar-right {
		cursor: pointer;
		display: flex;
		align-items: center;
		height: 40px;
	}
	.logo {
		display: flex;
		align-items: center;
		width: 48px;
		height: 48px;
		overflow: visible;

		@media (max-width: 575px) {
			padding-left: 3px;
		}
	}
	// .hamburger-menu .hamburger-react {
	//   @media (max-width: 575px) {
	//     width: 35px !important;
	//     height: 35px !important;
	//   }
	// }
	.close-icon {
		width: 25px;
		cursor: pointer;
		display: flex;
		align-items: center;
	}
	.header-sub-container {
		width: 415px;
		position: relative;
		z-index: 13;
		padding: 0 35px;
		@media (max-width: 767px) {
			width: 80%;
			padding: 0 27px;
			padding-left: 24px;
		}
		@media (max-width: 575px) {
			width: 100%;
		}

		// transition: all 0.5s ease;
		// // transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
		// transition-duration: 800ms !important;
	}
	.white-bg-header {
		background-color: $bg-white;
		height: 40px;
	}
	.menu-main-container {
		position: absolute;
		width: 415px;
		background-color: $bg-white;
		z-index: 12;
		// left: 0;
		top: 40px;
		opacity: 0;
		transform: translateY(-100%);
		-webkit-transform: translateY(-100%);
		-moz-transform: translateY(-100%);
		-ms-transform: translateY(-100%);
		-o-transform: translateY(-100%);
		transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		opacity: 0;
		// padding-right: 5px;
		@media (max-width: 767px) {
			width: 80%;
		}
		@media (max-width: 575px) {
			width: 100%;
		}
	}
	.menu-inner-container {
		padding: 0 27px;
		// padding-right: 22px;
		padding-bottom: 45px;
		// min-height: 500px;
		max-height: calc(100vh - 120px);
		overflow: auto;
		// z-index: 2000;
		@media (max-width: 767px) {
			max-height: calc(100vh - 180px);
			padding: 0 23px;
			// padding-right: 18px;
			padding-bottom: 45px;
		}
		// @media (max-width: 575px) {
		//   padding-right: 15px;
		//   padding-bottom: 20px;
		// }
	}
	.menu-main-container-open {
		transform: translateY(0%);
		-webkit-transform: translateY(0%);
		-moz-transform: translateY(0%);
		-ms-transform: translateY(0%);
		-o-transform: translateY(0%);
		opacity: 1;
	}
}
.header-container .card-header {
	padding-left: 8px !important;
	padding-right: 8px !important;
	@media (max-width: 767px) {
		padding-left: 4px !important;
		padding-right: 4px !important;
	}
	// &:last-child {
	//   padding: 0 !important;
	// }
}
.collapse-header-container .card-body {
	padding-left: 8px !important;
	padding-right: 8px !important;
	padding-top: 16px;
	&:last-child {
		padding-left: 0;
		padding-right: 0;
	}
	@media (max-width: 767px) {
		padding-left: 4px !important;
		padding-right: 4px !important;
	}
}
.logo-main-img {
	position: relative;
	left: -7px;
	cursor: pointer;
	@media (max-width: 575px) {
		margin-top: 3px;
	}
}
