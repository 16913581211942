@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
// Including bootstrap
@import 'bootstrap/bootstrap.scss';

// Including variables and mixins
// @import "partial/fonts";
@import 'partial/variables';
@import 'partial/mixins';
@import 'partial/reset';
@import 'partial/font';

html, body, #root {
	margin: 0;
	padding: 0;
	height: 100%;
	overflow: hidden;
  }
body {
	overscroll-behavior: auto;
	
	position: fixed;
	/* Prevents scrolling issues on iOS */
	width: 100%;
	height: 100%;
}

ul.react-datalist-input__listbox {
	position: relative !important;
	height: 150px !important;
	overflow: scroll !important;
	// padding: 8px;
}

.react-datalist-input__listbox-option {
	padding-left: 13px;
	padding-right: 13px;
}

.react-datalist-input__textbox {
	outline: none;
}

//  ripple loader
.ripple-container {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

@keyframes ldio-i8dcdsg1jeb {
	0% {
		top: 99.35999999999999px;
		left: 99.35999999999999px;
		width: 0;
		height: 0;
		opacity: 1;
	}

	100% {
		top: 18.63px;
		left: 18.63px;
		width: 161.45999999999998px;
		height: 161.45999999999998px;
		opacity: 0;
	}
}

.ldio-i8dcdsg1jeb div {
	position: absolute;
	border-width: 4.14px;
	border-style: solid;
	opacity: 1;
	border-radius: 50%;
	animation: ldio-i8dcdsg1jeb 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ldio-i8dcdsg1jeb div:nth-child(1) {
	border-color: #474747;
	animation-delay: 0s;
}

.ldio-i8dcdsg1jeb div:nth-child(2) {
	border-color: #8a8a8a;
	animation-delay: -0.5s;
}

.loadingio-spinner-ripple-jce4u9nidk {
	width: 207px;
	height: 207px;
	display: inline-block;
	overflow: hidden;
	background: none;
}

.ldio-i8dcdsg1jeb {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0;
	/* see note above */
}

.ldio-i8dcdsg1jeb div {
	box-sizing: content-box;
}

.loader-circle {
	width: 25px;
	display: inline-flex;
	aspect-ratio: 1;
	border-radius: 50%;
	background: radial-gradient(farthest-side, #ffffff 94%, #0000) top/3px 3px no-repeat,
		conic-gradient(#0000 30%, #ffffff);
	-webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);
	animation: l13 1s infinite linear;
}

@keyframes l13 {
	100% {
		transform: rotate(1turn);
	}
}

.ptr-element {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	// color: #aaa;
	z-index: 10;
	text-align: center;
	height: 50px;
	transition: all;
}

.ptr-element .genericon {
	opacity: 0.6;
	font-size: 34px;
	width: auto;
	height: auto;
	transition: all 0.25s ease;
	transform: rotate(90deg);
	margin-top: 5px;
}

.ptr-refresh .ptr-element .genericon {
	transform: rotate(270deg);
}

.ptr-loading .ptr-element .genericon,
.ptr-reset .ptr-element .genericon {
	display: none;
}

.loading {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	// opacity: 0.4;
	// background-color: aquamarine;
	margin-top: 3px;
	// padding: 12px 0 50px 5px;
	display: none;
}

.ptr-loading .loading {
	display: block;
}

.loading span {
	display: inline-block;
	vertical-align: middle;
	width: 10px;
	height: 10px;
	margin-right: 3px;
	transform: scale(0.3);
	border-radius: 50%;
	animation: ptr-loading 0.4s infinite alternate;
}

.loading-ptr-1 {
	animation-delay: 0 !important;
}

.loading-ptr-2 {
	animation-delay: 0.2s !important;
}

.loading-ptr-3 {
	animation-delay: 0.4s !important;
}

@keyframes ptr-loading {
	0% {
		transform: translateY(0) scale(0.3);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		background-color: #333; // dot loader color
		opacity: 1;
	}
}

.ptr-loading .refresh-view,
.ptr-reset .refresh-view,
.ptr-loading .ptr-element,
.ptr-reset .ptr-element {
	transition: all 0.25s ease;
}

.ptr-reset .refresh-view {
	transform: translate3d(0, 0, 0);
}

.ptr-loading .refresh-view {
	transform: translate3d(0, 30px, 0);
}