@import "../../../assets/scss/partial/variables";

.body-wrapper {
  height: 770px;
  overflow-y: auto;
  padding-right: 30px;
  @media (max-width: 575px) {
    overflow-y: unset;
  }
  .privacy-header-container {
    padding-bottom: 25px;
    border-bottom: 1px dotted $border-color;
  }
  .body-heading {
    font-size: 17px;
    font-weight: bold;
    line-height: 21px;
    padding-top: 21px;
    margin-bottom: 0;
  }
  .personal-data-wrapper {
    padding-bottom: 21px;
    border-bottom: 1px dotted $border-color;
  }
  .single-content-wrapper {
    padding-top: 24px;
    gap: 15px;
  }
  .single-content-heading {
    font-size: 13px;
    line-height: 19px;
    font-weight: bold;
    margin-bottom: 0;
    // padding-top: 24px;
  }
  .single-content-sub-heading {
    padding-top: 10px;
    color: #615e5e;
    font-size: 13px;
    line-height: 19px;
    font-weight: bold;
    margin-bottom: 0;
  }
  .single-content-para {
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 0;
  }
  .footer-wrapper {
    padding: 24px 0;
    padding-bottom: 0;
    .para-frist {
      font-size: 11px;
      margin: 10px 0;
    }
    .para-second {
      font-size: 11px;
    }
    .single-link {
      cursor: pointer;
      color: $default-color;
      font-size: 11px;
      text-decoration: none;
      border-bottom: 1px solid #f0f0f0;
      &:hover {
        color: #121518;
        border-bottom-color: #d6d6d6;
        -webkit-transition: 0.1s linear all;
        transition: 0.1s linear all;
      }
    }
  }
}
