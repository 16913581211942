@import "../../../../assets/scss/partial/variables";

.cart-slider-zoom {
  background-color: black;
  padding: 6px 8px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon{
    fill: white; 
    stroke: white;
  }
}
