@import "../../../assets/scss/partial/variables";

.single-order-item {
  &-container {
    display: flex;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
    h4 {
      margin-bottom: 0;
      font-weight: 400;
      line-height: 1.5;
      color: #6d6e78;
      font-size: 0.75rem;
    }
    .author-name {
      margin-bottom: 3px;
      margin-top: -4px;
      font-size: 0.75rem;
      font-weight: 600;
      color: #30313d;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .top-content {
      margin-bottom: 8px;
    }
  }

  &-left {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    background: #000;
    background-image: linear-gradient(to bottom, #fafafa, #e8e8e8);
    margin-right: 15px;
    cursor: pointer;

    img {
      // width: 90px;
      // height: 90px;
      width: 100%;
      max-width: 90px;
      height: auto;
      max-height: 100%;
    }
  }

  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
