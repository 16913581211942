@import "../../../assets/scss/partial/variables";

.collapse-header-container {
  .card {
    border: none;
    border-radius: 0;
  }

  .card-header {
    padding: 0;
    border: none;
    background-color: transparent;
    margin-bottom: 0 !important;
  }

  .card-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

.single-list-button {
  border: none;
  box-shadow: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding: 25px 0 10px 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: #000;

  &:hover {
    border-bottom: 2px solid black;
    font-weight: 600;
  }

  &:focus {
    outline: none;
  }
}

.active-list-button {
  border-bottom: 2px solid black;
  font-weight: 600;
}

.total-item-price {
  font-weight: 400;
}

.product-showing {
  font-weight: 400;
}