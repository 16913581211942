@import "../../../assets/scss/partial/variables";

.checkout-tab-container {
  margin-top: 8px;

  .payment-header {
    font-size: 12px;
    margin-bottom: 12px;
  }

  .container-checkbox {
    position: relative;
    padding-left: 30px;
    font-size: 12px;
  }

  .container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: -2px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #666666;
    margin-top: 1px;
    cursor: pointer;
    // border-radius: 4px;
  }

  .time-zone {
    width: 99%;
    padding-left: 1px;
  }

  // .timezone--wrapper {
  //   z-index: 5000000000;
  //   position: absolute;
  // }

  .container-checkbox input:checked~.checkmark {
    background-color: #056fdd;
    border: 1px solid #fff;
    margin-top: 1px;
    // border-radius: 4px;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container-checkbox input:checked~.checkmark:after {
    display: block;
  }

  .container-checkbox .checkmark:after {
    left: 6px;
    top: 1px;
    width: 6px;
    height: 13px;
    border: solid #fff;
    /* border-radius: 4px; */
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .input-style-custom {
    border: 2px solid #ececec !important;
    background-color: #ececec;
    height: 38px;
    border-radius: 10px;
    font-size: 13px;

    &:focus {
      // border-width: 2px;
      box-shadow: none;
      // border-color: #a8c9f4;
      border: 2px solid #a8c9f4 !important;
      // color: #a8c9f4;
      background-color: #ececec;
      outline-width: 0;
      outline: none;
    }

    &:focus-visible {
      box-shadow: none;
      outline-width: 0;
      outline: none;
      // border-width: 2px;
    }
  }

  .name-container {
    margin-top: 16px;
    margin-bottom: 16px;
    gap: 15px;
  }

  .input-style-custom-address {
    margin-bottom: 16px;
  }

  .timezone--wrapper .css-13cymwt-control,
  .timezone--wrapper .css-t3ipsp-control {
    height: 38px;
    border-color: #e6e6e6;
    box-shadow: none;
    background-color: #ececec;
    cursor: pointer;
    border-radius: 10px;
    font-size: 12px;
  }

  .billing-head {
    font-size: 12px;
    margin-bottom: 12px;
  }

  .billing-address {
    margin-bottom: 35px;
  }

  .form-control {
    background-color: #ececec !important;
    border-radius: 10px !important;
    border: none;

    &:focus {
      // border-width: 2px;
      box-shadow: none;
      // border-color: #a8c9f4;
      // border: 2px solid #a8c9f4;
      // color: #a8c9f4;
      background-color: #ececec;
    }
  }

  .was-validated .form-control:invalid:focus,
  .form-control.is-invalid:focus {
    box-shadow: none;
  }
}

.save-btn {
  height: 64px;
  margin-top: 30px;
  border-radius: 5px !important;
  width: 100%;
  background-color: black !important;

  &:hover {
    background-color: black !important;
    color: $bg-white !important;
  }
}

.input-info-container {
  padding-bottom: 20px;
}

.info-header {
  font-size: 13px;
  font-weight: 700;
  margin: 10px 0;
  margin-top: 0;
}

.info-header-sub {
  font-size: 13px;
  font-weight: 700;
  margin: 10px 0;
  margin-top: 1.25rem;
}

.input-label {
  font-size: 12px;
  margin-bottom: 0;
}