@import '../../../assets/scss/partial/variables';

.now-showing-tab-container {
  .box-container {
    display: flex;
    align-items: center;
    background-color: #000;
    color: #fff;
    border-radius: 6px;
    padding: 5px;
    height: auto;
    //min-height: 60px;

    @media screen and (max-width: 399px) {
      height: auto;
      gap: 6px;
    }
  }

  .filter-input {
    margin-top: 5px;
    height: 45px;
    border: 1px solid #ced4da;

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: #ced4da;
      background-color: #ececec;
    }
  }

  .search-product-field {
    height: 200px;
    border: 1px solid #ced4da;
    border-top: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    overflow-y: auto;
  }

  .open-filter-input {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #ced4da;
  }

  .custom-list-container {
    list-style: none;
    padding-left: 10px;
    padding-top: 10px;
  }

  .single-list {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
    cursor: pointer;
  }
}

.single-box {
  padding: 14px 10px;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.5s all ease-in-out;

  @media screen and (max-width: 399px) {
    padding: 10px 8px;
  }
  @media screen and (max-width: 359px) {
    padding: 8px 6px;
  }

  &:focus {
    border-color: #056fdd;
  }

  &--all-products {
    min-width: max-content;
    flex: 1;

    @media screen and (max-width: 399px) {
      min-width: 180px;
    }
    @media screen and (max-width: 359px) {
      min-width: unset;
    }
  }
}

.active-tab {
  // border: 2px solid #056fdd;
  background-color: #fff;
}

.active-box-heading {
  // color: #056fdd;
  color: #000;
}

.box-heading {
  font-size: 13px;
  font-weight: 600;
  text-align: center;

  @media screen and (max-width: 399px) {
    font-size: 12px;
    font-weight: 500;
  }

  @media screen and (max-width: 359px) {
    font-size: 11px;
  }
}

.box-container {
  gap: 10px;

  @media screen and (max-width: 280px) {
    flex-direction: column !important;
  }
}

.box-panel {
  padding: 15px;
  width: 100%;
  border: 1px solid #e7e7e7;
  border-radius: 6px;
  margin-top: 8px;
}

.input-container {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
}

.input-container:focus-within label {
  transform: translate(0, 12px) scale(0.8) !important;
  color: #9a9ba0 !important;
}

.input-container .filled {
  transform: translate(0, 12px) scale(0.8) !important;
}

.input-container label {
  position: absolute !important;
  pointer-events: none !important;
  transform: translate(0, 23px) scale(1) !important;
  transform-origin: top left !important;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  color: #5d5d67 !important;
  font-size: 14px !important;
  line-height: 1 !important;
  left: 13px !important;
  margin-bottom: 0 !important;
  top: 2px !important;
}

.now-showing-tab-container .input-container input {
  height: 64px !important;
  padding: 20px 16px 4px 13px !important;
  font-size: 14px !important;
  line-height: 1 !important;
  outline: none !important;
  // transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  // padding: 0.75rem !important;
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease !important;
  border: 1px solid #e6e6e6 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02) !important;
  border-radius: 5px !important;
}

.input-container input:focus {
  box-shadow: none !important;
  border: 1px solid #82b9f0 !important;
  height: 64px !important;
  padding-top: 20px !important;
}

.input-container input:focus::placeholder {
  opacity: 1 !important;
}

.input-container input::placeholder {
  opacity: 0 !important;
}

.react-datalist-input__textbox {
  padding-right: 36px !important;
}

.remove-filter-btn {
  top: 26px;
  right: 8px !important;
  border: none;
  outline: none;
  background: none;
  position: absolute;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.now-showing-item {
  margin-bottom: 10px;

  input[type="radio"] {
   top:3px;
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 5px;
  }

  //@media (max-width: 1399.98px) {
  //  margin-bottom: 14px;
  //}
  //@media (max-width: 1199.98px) {
  //  margin-bottom: 12px;
  //}
  //@media (max-width: 991.98px) {
  //  margin-bottom: 10px;
  //}
  //@media (max-width: 767.98px) {
  //  margin-bottom: 8px;
  //}
  //@media (max-width: 575.98px) {
  //  margin-bottom: 6px;
  //}

  &__check {
    font-size: 14px;
    font-weight: 400;
    color: black;
    display: flex;
    align-items: center;

    & label {
      color: black !important;
      display: flex;
      align-items: center;
      overflow: hidden;
      text-after-overflow: ellipsis !important;
      white-space: nowrap;
    }

    & input[type='radio'] {
      accent-color: gray;
      -ms-transform: scale(1.4); /* IE 9 */
      -webkit-transform: scale(1.4); /* Chrome, Safari, Opera */
      transform: scale(1.4);

      //@media (max-width: 1199.98px) {
      //  -ms-transform: scale(1.3); /* IE 9 */
      //  -webkit-transform: scale(1.3); /* Chrome, Safari, Opera */
      //  transform: scale(1.3);
      //}
      //@media (max-width: 991.98px) {
      //  -ms-transform: scale(1.2); /* IE 9 */
      //  -webkit-transform: scale(1.2); /* Chrome, Safari, Opera */
      //  transform: scale(1.2);
      //}
      //@media (max-width: 767.98px) {
      //  -ms-transform: scale(1.1); /* IE 9 */
      //  -webkit-transform: scale(1.1); /* Chrome, Safari, Opera */
      //  transform: scale(1.1);
      //}
      //@media (max-width: 575.98px) {
      //  -ms-transform: scale(1); /* IE 9 */
      //  -webkit-transform: scale(1); /* Chrome, Safari, Opera */
      //  transform: scale(1);
      //}

      &:checked {
        accent-color: black;
      }
    }
  }

  &__icon {
    width: 28px;
    margin-right: 5px;
    margin-left: 0px;

    //@media (max-width: 1399.98px) {
    //  margin-right: 9px;
    //  margin-left: 4px;
    //  height: 19px;
    //}
    //@media (max-width: 1199.98px) {
    //  margin-right: 8px;
    //  margin-left: 3px;
    //  height: 18px;
    //}
    //@media (max-width: 991.98px) {
    //  margin-right: 7px;
    //  margin-left: 2px;
    //  height: 17px;
    //}
    //@media (max-width: 767.98px) {
    //  margin-right: 6px;
    //  margin-left: 1px;
    //  height: 16px;
    //}
    //@media (max-width: 575.98px) {
    //  margin-right: 5px;
    //  margin-left: 0;
    //  height: 15px;
    //}
  }

  & .form-check-label,
  & .form-check-input {
    cursor: pointer;
  }
}
