@import "../../../assets/scss/partial/variables";

.cart-wrapper {
  width: 100%;
  position: fixed;
  bottom: 0;

  &.active {
    z-index: 2000;
  }

  .open-cart-container {
    position: relative;
    bottom: 0px;
    z-index: 1000;
    width: 420px;
    display: flex;
    justify-content: space-between;
    background-color: $bg-white;
    opacity: 0;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    padding: 20px 35px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    @media (max-width: 767px) {
      width: 80%;
      padding-right: 27px;
      padding-left: 27px;
    }

    @media (max-width: 575px) {
      width: 100%;
    }
  }

  .open-cart-content {
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
    opacity: 1;
  }

  .select-wrapper {
    width: 100%;
    display: inline-block;

    // padding-top: 0.8rem;
    &:focus {
      outline: none;
    }
  }

  .custom-select-label {
    display: block;
    margin-bottom: 0px;
    font-size: 0.75rem;
    position: absolute;
    top: 13px;
    left: 13px;
    color: #6d6e78;
    opacity: 0.8;
    z-index: 1;

    @media (max-width: 575px) {
      top: 10px;
    }
  }

  .form-select {
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    width: 100%;
    position: relative;

    // padding-right: 0.75rem;
    // padding-bottom: 0;
    // padding-left: 0.75rem;
    appearance: none;
    background-color: #fff;
    // border-color: #737373;
    font-size: 0.8rem;
    line-height: 1.1;
    --tw-shadow: 0 0 #0000;
    background-image: url("https://cdn1.iconfinder.com/data/icons/arrows-vol-1-4/24/dropdown_arrow-512.png");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.2em 1.2em;
    padding: 0.75rem;
    padding-right: 2rem;
    padding-top: 27px !important;
    cursor: pointer;
    z-index: 50;
    background-color: transparent;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
    height: 64px;
    color: #30313d;
    font-weight: 600;

    &:focus {
      outline: none;
      border: 1px solid #82b9f0;
    }

    @media (max-width: 757px) {
      width: 100%;
    }

    @media (max-width: 575px) {
      height: 55px;
      font-size: 10px;
    }
  }

  .select-container {
    position: relative;
    width: 100%;

    @media (max-width: 757px) {
      width: 100%;
    }
  }

  .select-iconbar {
    position: absolute;
    width: 1px;
    height: 25px;
    background-color: $default-color;
    top: 9px;
    right: 35px;
  }

  .cart-content-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;

    @media (max-width: 757px) {
      gap: 10px;
    }
  }

  .add-cart-btn {
    width: 75%;
    font-size: 12px;
    border-radius: 5px;

    &:hover {
      background-color: $default-color;
      color: $bg-white;
    }

    @media (max-width: 757px) {
      width: 100%;
    }

    @media (max-width: 575px) {
      width: 190px;
      font-size: 10px;
    }
  }
}