@import "../../../assets/scss/partial/variables";

.body-wrapper-term-condition {
  height: 770px;
  overflow-y: auto;
  padding-right: 30px;
  .heading-term-condition {
    font-size: 30px;
    color: #141414;
    line-height: 1.6;
    margin-bottom: 60px;
  }
  .head-para-term-condition {
    font-size: 14px;
  }
  .single-text-accordion {
    font-size: 13px;
    margin-bottom: 0;
  }
  .button-border {
    border-bottom: 1px dotted #ddd !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  ul {
    padding: 0;
    margin-bottom: 0;
  }
  li {
    line-height: 19px;
    font-size: 14px;
    margin-left: 30px;
    margin-top: 2px;
  }
  .card-header {
    padding: 0;
  }
  .toggle-button {
    padding: 12px 5px 13px 10px;
    border: 1px solid #c0c1c1;
    background-color: transparent;
    width: 100%;
    height: 100%;
    text-align: left;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom-color: #a8aaab;
    // -webkit-box-shadow: 0 1px 0 #ebebec;
    box-shadow: 0 1px 0 #ebebec;
    // box-shadow: 0 1px 0 rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 5px;
    &:focus {
      outline: none;
    }
    .button-container {
      gap: 15px;
      align-items: center;
    }
  }
  .card-body {
    border-bottom: 1px solid #c0c1c1;
    border-left: 1px solid #c0c1c1;
    border-right: 1px solid #c0c1c1;
    padding: 12px 5px 13px 10px;
    box-shadow: 0 1px 0 #ebebec;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .dotted-border {
    border-bottom: 1px dotted #ddd;
  }
  .card {
    border: none;
    border-radius: 5px;
  }
  .privacy-header-container {
    padding-bottom: 25px;
    border-bottom: 1px dotted $border-color;
  }
  .body-heading {
    font-size: 17px;
    font-weight: bold;
    line-height: 21px;
    padding-top: 21px;
    margin-bottom: 0;
  }
  .personal-data-wrapper {
    padding-bottom: 21px;
    border-bottom: 1px dotted $border-color;
  }
  .single-content-wrapper {
    padding-top: 24px;
    gap: 15px;
  }
  .single-content-heading {
    font-size: 13px;
    line-height: 19px;
    font-weight: bold;
    margin-bottom: 0;
    // padding-top: 24px;
  }
  .single-content-sub-heading {
    padding-top: 10px;
    color: #615e5e;
    font-size: 13px;
    line-height: 19px;
    font-weight: bold;
    margin-bottom: 0;
  }
  .single-content-para {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 0;
  }
  .heading-term-condition-use {
    font-size: 24px;
    padding-top: 50px;
    color: #141414;
    line-height: 1.6;
    padding-bottom: 15px;
    margin-bottom: 0;
    font-weight: bold;
  }
  .heading-term-condition-content-web {
    color: #444;
    font-size: 20px;
    padding-top: 45px;
    line-height: 1.6;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .single-link {
    cursor: pointer;
    color: $default-color;
    font-size: 14px;
    text-decoration: none;
    border-bottom: 1px solid #f0f0f0;
    &:hover {
      color: #121518;
      border-bottom-color: #d6d6d6;
      -webkit-transition: 0.1s linear all;
      transition: 0.1s linear all;
    }
  }
  .footer-wrapper {
    padding: 24px 0;
    padding-bottom: 0;
    .para-frist {
      font-size: 11px;
      margin: 10px 0;
    }
    .para-second {
      font-size: 11px;
    }
    .single-link {
      cursor: pointer;
      color: $default-color;
      font-size: 11px;
      text-decoration: none;
      border-bottom: 1px solid #f0f0f0;
      &:hover {
        color: #121518;
        border-bottom-color: #d6d6d6;
        -webkit-transition: 0.1s linear all;
        transition: 0.1s linear all;
      }
    }
  }
}
