//    Font Family

$primary-font: "Arial";
// $user-nav-font: "Ubuntu", sans-serif;

// Text Color

$default-color: black;
// $primary-color: #008cc9;
// $light-pink-color: #ff7374;
// $white-color: #ffffff;
// $text-color-1: #1e232c;
// $text-color-2: #f58634;
// $text-color-3: #666666;
// $light-yellow: #ffd052;
// $light-blue: #ccf0ff;
// $light-gray: #d9d9d9;
// $light-gray-1: #6a707c;
// $text-color-second: #727478;
// $text-color-third: #1e232c;
$placeholder-color: #8391a1;
$placeholder-focus-color: #bebfc0;
// $black-color: #000;
// $text-color-4: #058e6e;
// $text-color-5: #555555;

// Background Color
$bg-color-1: #ececec;
$bg-white: #fff;
// $background-color-1: #058e23;
// $background-color-2: #f7f8f9;
// $background-color-3: #f6f6f6;
// $background-color-4: #eefaff;
// $background-color-5: #E62424;

// Border Color
$border-color: #dfdfdf;
// $border-color-2: #e8e8e8;
// $border-color-3: #e8ecf4;
// $border-color-4: #eaf9ff;
// $border-color-5: #d8f0fb;
// $border-color-6: #bdc5cd;

// Font-size
$default-font-size: 16px;
