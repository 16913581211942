@import "../../../assets/scss/partial/variables";

.cookie-condition-close {
  display: none;
}
.cookie-condition {
  z-index: 10;
  position: fixed;
  bottom: 16px;
  box-shadow: 0 8px 48px rgba(0, 0, 0, 0.15);
  width: 960px;
  // height: 260px;
  padding: 16px;
  @media (max-width: 991px) {
    width: 100%;
  }
  background-color: #fff;
  .close-btn {
    z-index: 1;
    top: 6px;
    right: 0;
    margin: 10px;
    min-width: 32px;
    height: 32px;
    padding: 6px;
    font-size: 24px;
    line-height: 0;
    font-weight: lighter;
    cursor: pointer;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 4px;
    opacity: 0.7;
    align-self: flex-end;
    position: absolute;
    font-size: 14px;
    color: #000000;
    background-color: $bg-white;
    &:hover {
      opacity: 1;
    }
  }
  .content-para {
    font-size: 14px;
    color: #000000;
  }
  & img {
    // width: 48px;
    // height: 48px;
    object-fit: contain;
  }
}
// .single-btn {
//   appearance: none !important;
//   padding: 8px 32px !important;
//   border-radius: 64px !important;
//   cursor: pointer !important;
//   font-weight: 700 !important;
//   font-size: 14px !important;
//   margin-top: 4px !important;
//   margin-bottom: 4px !important;
//   text-align: center !important;
//   border: 0 !important;
//   background-color: #1a1a1a !important;
//   color: #fff !important;
// }
// .bottom-btn-container {
//   @media (max-width: 639px) {
//     flex-direction: column-reverse;
//   }
// }
