@import '../../../assets/scss/partial/variables';

.border-switcher {
  position: fixed;
  z-index: 2000;
  bottom: 0;
  width: auto;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  padding: 10px 35px;
  display: flex;
  opacity: 0;
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;

  &__zoom-icon{
    fill: white; 
    stroke: white;
    width:30px;
  }

  @media screen and (max-width: 767px) {
    width: 80%;
    padding-right: 27px;
    padding-left: 27px;
    justify-content: space-between;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
  }
  @media screen and (max-width: 380px) {
    padding-right: 15px;
    padding-left: 15px;
  }

  &__zoom {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    // padding: 5px 10px;
    height: 32px;
    width: 36px;
    border-radius: 6px;
    cursor: pointer;
  }

  &--show {
    transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
    opacity: 1;
  }

  &__switch {
    @media screen and (max-width: 767px) {
      flex: 1;
    }

    .box-container {
      display: flex;
      align-items: center;
      background-color: #000;
      color: #fff;
      border-radius: 6px;
      padding: 5px;
      height: 32px;
    }

    .single-box {
      padding: 2px 14px;
      border-radius: 6px;
      cursor: pointer;
      transition: 0.5s all ease-in-out;

      @media screen and (max-width: 767px) {
        width: 50%;
      }

      @media screen and (max-width: 450px) {
        padding: 2px 10px;
      }

      &:focus {
        border-color: #056fdd;
      }
    }

    .active-tab {
      // border: 2px solid #056fdd;
      cursor: unset;
      background-color: #fff;
    }

    .active-box-heading {
      // color: #056fdd;
      color: #000;
    }

    .box-heading {
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      text-wrap: nowrap;
    }

    .box-container {
      gap: 10px;
    }
  }

  &__cart-btn {
    background: black;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 23px;
    border-radius: 6px;
    cursor: pointer;

    @media screen and (max-width: 450px) {
      padding: 6px 10px;
    }

    & img {
      height: 14px;
    }
  }
}
