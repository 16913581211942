@import "../../../assets/scss/partial/variables";

.primary-btn {
  padding: 0.4rem 0.8rem;
  border-radius: 8px;
  // font-family: $primary-font;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: $bg-white;
  border: 1px solid $default-color;
  transition: 0.3s ease-in-out all;
  &:focus {
    outline: 0;
  }
}

.primary-btn-transparent {
  background-color: $bg-white;
  color: $default-color;
  &:hover {
    background-color: $default-color;
    color: $bg-white;
  }
}
.primary-btn-solid {
  background-color: $default-color;
  color: $bg-white;
  &:hover {
    background-color: $bg-white;
    color: $default-color;
  }
}
.primary-btn.font500 {
  font-weight: 500;
}
.btn-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
// Play button

.btn-play-pause-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  margin-left: 44px;
  margin-right: 44px;
  @media (max-width: 1300px) {
    margin-left: 35px;
    margin-right: 35px;
  }
  cursor: pointer;
}
.play-icon {
  width: 52px;
  height: 52px;
}

.audio-modal-container {
  padding: 4px 12px;
  border-radius: 8px;
  background-color: $bg-white;
  margin-right: 8px;
  cursor: pointer;
  .icon {
    margin-right: 10px;
  }
  .text {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: $default-color;
    @media (max-width: 1300px) {
      font-size: 10px;
    }
    @media (max-width: 320px) {
      font-size: 8px;
    }
  }
  @media (max-width: 1300px) {
    padding: 4px 8px;
  }
  @media (max-width: 425px) {
    padding: 4px 4px;
  }
  @media (max-width: 320px) {
    margin-right: 4px;
  }
}
