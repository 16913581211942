@import "../../../assets/scss/partial/variables";

.customer-service-tab-container {
  .single-header {
    font-size: 0.93rem;
    font-weight: 600;
    color: #30313d;
  }
  .italic-para {
    font-style: italic;
  }
  .single-para {
    font-size: 12px;
    margin-bottom: 13px;
    color: #6d6e78;
    font-size: 0.75rem;
  }
  .para-bottom {
    font-size: 12px;
    color: #6d6e78;
    font-size: 0.75rem;
  }
  .single-header-sub {
    margin-top: 35px;
  }
  .single-link {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }
  .single-link-mail {
    font-size: 12px;
    text-decoration: underline;
    color: #6d6e78;
  }
  .single-link-dollar {
    text-decoration: none;
    font-size: 12px;
    color: #6d6e78;
  }
  .link-single:hover {
    text-decoration: none;
  }
  .link-container {
    gap: 8px;
    @media (max-width: 767px) {
      gap: 8px;
    }
  }
  .copy-right {
    font-size: 12px;
    margin-top: 20px;
    color: #6d6e78;
  }
  .link-single {
    font-size: 12px;
  }
}
