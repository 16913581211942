/* =================================== */
/*  Basic Style 
/* =================================== */
@import "../../../assets/scss/partial/variables";

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: black !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
  position: relative;
  background-color: #ececec;
}
p,
a {
  font-size: $default-font-size;
  margin-bottom: 0;
}
::-webkit-scrollbar-thumb {
  background-color: #dadce0;
  border: 2px solid #dadce0;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
  background-color: $bg-white;
}
::-webkit-scrollbar-track {
  border: 2px solid transparent;
  border-radius: 10px;
  --webkit-box-shadow: inset 0 0 6px#E6E6E6;
}
