@import '../../assets/scss/partial/variables';

.slide-wrapper {
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -os-user-select: none;
  user-select: none;
}

.home-container {
  .swiper-slide-img-container {
    // max-height: calc(100vh - 150px);
    // height: calc(100vh - 0px);
    width: 100%;
    object-fit: contain;
    transform: scale(1);
    padding: 0 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../../public/images/wide.png');
    background-size: cover;

    img {
      object-fit: contain;
      padding-top: 50px;
      padding-bottom: 35px;
      // pointer-events: none;
      cursor: pointer;

      @media (max-width: 767px) {
        padding-bottom: 50px;
      }
    }

    @media (max-width: 767px) {
      background-image: url('../../../public/images/wide.png');
      // background-color: #eff0ef;
      background-size: cover;
      padding: 0;
    }
    @media (max-width: 450px) {
      background-image: url('../../../public/images/tall.png');
    }
  }

  .frist-video {
    height: calc(100vh - 0px);
    width: 100%;
    transform: scale(1);
    background-repeat: repeat, repeat;
    transition: 1s opacity;
    object-fit: cover;

    @media (max-width: 767px) {
      height: calc(100vh - 50px);
    }
  }

  .item-name {
    position: fixed;
    z-index: 2000;
    bottom: 10px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    // cursor: pointer;
    
    margin-bottom: -20px;
    padding-top: 28px;
    padding-bottom: 20px;
    @media (max-width: 767px) {
      margin-bottom: 0px;
      padding: 20px;
    }
    @media (max-width: 575px) {
      margin-bottom: 0px;
      bottom: 17px;
    }
  }

  .mute-icon {
    width: 20px;
    height: 20px;
  }

  .mute-button {
    background-color: 'rgb(255 255 255 / 50%)';
    width: 35px;
    height: 35px;
    border-radius: 5px;
    position: absolute;
    right: 50px;
    bottom: 50px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    @media (max-width: 767px) {
      bottom: 95px;
    }
  }
}

.swiper-slide {
  overflow: hidden;
}

.hamburger-react {
  margin-right: -10px;
  @media (max-width: 575px) {
    margin-right: 0px;
  }
}

.hamburger-react > div > div {
  height: 3px !important;
}

.arrow-slider {
  font-size: 6vmin;
  padding: 0.5rem;
  background: #f3f3f3;
  border-radius: 50%;
  border: 1px solid #e2e2e2;
  color: #4a4a4a !important;
  transition: all ease;
  animation-duration: 5ms;
  height: 50px;
  width: 50px;
  @media (max-width: 575px) {
    height: 40px;
    width: 40px;
  }
}

.arrow-slider:hover {
  background: #ebe9e9;
}

.arrow-slide-main {
  position: fixed;
  top: 50%;
  right: 2%;
  cursor: pointer;
  z-index: 4;
  transform: translate(-50%, -2%);
  @media (max-width: 575px) {
    right: 0;
  }
}

//zoom content
.zoom-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zoom-container.zoomed {
  cursor: move;
}

.zoom-out-icon {
  position: relative;
  top: 12px;
  right: 12px;
  background-color: #000;
  padding: 5px !important;
  z-index: 100000000;
  height: 25px !important;
  width: 25px !important;
  border-radius: 50% !important;
  cursor: pointer !important;
}

.zoom-image {
  cursor: pointer;
}

.modal-overlay {
  touch-action: none;
}

.modal-overlay__zoom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 40px;

  @media (max-width: 575px) {
    padding: 10px;
  }
}

.zoom-modal-close {
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.modal-content {
  touch-action: pinch-zoom; 
}

.modal-content__zoom {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;
  overflow: hidden;

  @media (max-width: 575px) {
    height: 90% !important;
  }
}

.react-transform-wrapper {
  height: 100% !important;
}

.tools {
  position: absolute;
  bottom: 10px;

  & button {
    border-radius: 5px;
    border: none;
    margin: 0 5px;
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255, .3);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 20px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

button.Tab.p-Tab-selected.Tab-selected {
  border-color: black !important;
}

.slider-contents {
  width: 100%;
  height: auto;
  position: fixed;
  left: 0;
  bottom: 10px;
  z-index: 2001;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &__name {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }

  & .border-switcher__zoom {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .7);
    transition: all .2s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    &:hover {
      background-color: black;
    }
  }
}
