@import "../../../assets/scss/partial/variables";

.order-list-tab-container {
  .total-order {
    font-size: 12px;
  }
  .counter-container {
    border: solid #e2e2e2;
    border-width: 1px;
    height: 35px;
    // padding: 0 15px;
  }
  .minus-item,
  .item-quantity,
  .plus-item,
  .remove-item {
    font-size: 12px;
    cursor: pointer;
  }
  .remove-item {
    padding-left: 15px;
    text-decoration: underline;
  }
  .empty-order-text {
    font-size: 12px;
  }
  .minus-item {
    padding-left: 10px;
    background-color: #f3f3f3;
    height: 100%;
    padding-right: 10px;
    align-items: center;
    display: flex;
  }
  .plus-item {
    padding-left: 10px;
    background-color: #f3f3f3;
    height: 100%;
    padding-right: 10px;
    align-items: center;
    display: flex;
  }
  .item-quantity {
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    align-items: center;
    display: flex;
  }
  .remove-item {
    height: 35px;
    width: 35px;
    background-color: #f3f3f3;
    border-radius: 50%;
    border: 1px solid #e2e2e2;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0;
    margin-left: 10px;
    &:hover {
      background-color: #e2e1e1;
    }
  }
}
