@import "../../../assets/scss/partial/variables";

.close-button-container {
  cursor: pointer;
  right: -10px;
  top: -10px;
  position: absolute;
  width: fit-content;
  height: fit-content;
  @media (max-width: 767px) {
    right: -5px;
  }
}
.close-button {
  z-index: 2;
  position: relative;
  transition: all 0.2s ease;
  &:hover {
    transform: scale(1.2);
  }
}
