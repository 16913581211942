@import "../../../assets/scss/partial/variables";

.checkout-payment-container {
  .box-container {
    margin-bottom: 0px;
  }
  .active-box-heading {
    color: $bg-white;
  }
  .single-box {
    border: none;
    background-color: #ececec;
  }
  .active-tab {
    background-color: #056fdd;
  }
}
