@import "../../../assets/scss/partial/variables";

.body-wrapper-complete {
  overflow-y: auto;
  padding-right: 30px;
  .privacy-header-container {
    padding-bottom: 25px;
    border-bottom: 1px dotted $border-color;
  }
  .body-heading {
    font-size: 17px;
    font-weight: bold;
    line-height: 21px;
    padding-top: 21px;
    margin-bottom: 0;
  }
  .personal-data-wrapper {
    padding-bottom: 21px;
    border-bottom: 1px dotted $border-color;
  }
  .single-content-wrapper {
    padding-top: 24px;
    gap: 15px;
  }
  .single-content-heading {
    font-size: 17px;
    line-height: 19px;
    font-weight: bold;
    margin-bottom: 0;
    padding-bottom: 5px;
    // padding-top: 24px;
  }
  .single-content-sub-heading {
    padding-top: 10px;
    font-size: 13px;
    line-height: 19px;
    font-weight: bold;
    margin-bottom: 0;
  }
  .single-content-para {
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 0;
  }
  .footer-wrapper {
    padding: 24px 0;
    padding-bottom: 0;
    .para-frist {
      font-size: 11px;
      margin: 10px 0;
    }
    .para-second {
      font-size: 11px;
    }
  }
  .single-link {
    cursor: pointer;
    border-bottom: 1px solid #f0f0f0;
    &:hover {
      color: #121518;
      border-bottom-color: #d6d6d6;
      -webkit-transition: 0.1s linear all;
      transition: 0.1s linear all;
    }
  }
  .content-wrapper {
    padding-top: 21px;
    padding-bottom: 21px;
    border-bottom: 1px dotted $border-color;
    ul {
      padding: 0;
    }
    li {
      line-height: 19px;
      font-size: 13px;
      margin-left: 30px;
      margin-top: 2px;
    }
  }
  .card-header {
    padding: 0;
  }
  .toggle-button {
    padding: 12px 5px 13px 10px;
    border: 1px solid #c0c1c1;
    background-color: transparent;
    width: 100%;
    height: 100%;
    text-align: left;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom-color: #a8aaab;
    // -webkit-box-shadow: 0 1px 0 #ebebec;
    box-shadow: 0 1px 0 #ebebec;
    // box-shadow: 0 1px 0 rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 5px;
    &:focus {
      outline: none;
    }
    .button-container {
      gap: 15px;
      align-items: center;
    }
  }
  .card-body {
    border-bottom: 1px solid #c0c1c1;
    border-left: 1px solid #c0c1c1;
    border-right: 1px solid #c0c1c1;
    padding: 12px 5px 13px 10px;
    box-shadow: 0 1px 0 #ebebec;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  // .accordion-body {
  //   border-top: none;
  //   border: 1px solid #c0c1c1;
  //   padding: 12px 5px 13px 10px;
  //   // box-shadow: 0 1px 0 #ebebec;
  //   // border-bottom-right-radius: 5px;
  //   // border-bottom-left-radius: 5px;
  // }

  .button-border {
    border-bottom: 1px dotted #ddd;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .dotted-border {
    border-bottom: 1px dotted #ddd;
  }
  .single-text-accordion {
    font-size: 13px;
    margin-bottom: 0;
  }
  .card {
    border: none;
    border-radius: 5px;
  }
  .sub-heading-cat {
    color: #505762;
  }
  .relative {
    position: relative;
  }
  .tag-image {
    position: absolute;
    top: 0;
    left: 10px;
  }
  .tag-btn-container {
    padding-left: 45px;
  }
}
.custom-height-location-cookie {
  height: 550px;
}
.body-wrapper-cookie-height {
  height: 770px;
}
