@import "../../../assets/scss/partial/variables";

.privacy-policy-container {
  // overflow: hidden;
  width: 800px !important;
  padding: 25px 30px;
  padding-right: 0px;
  margin: auto;
  border-radius: 3px !important;
  border: none !important;
  .heading {
    font-size: 19px;
  }
  .head-para {
    font-size: 13px;
    font-weight: 400;
  }
  .modal-body {
    padding: 0px;
    padding-top: 0;
    position: static;
  }
  .modal-title-class {
    width: 100%;
  }
  .modal-header-class {
    border-bottom: 0;
    padding: 0px;
    display: unset;
    // padding-bottom: 15px;
  }
  .modal-footer-class {
    border-top: 0;
  }
}

@media (max-width: 575px) {
  .modal-dialog,
  .modal-content {
    /* 80% of window height */
    height: 80% !important;
  }

  .modal-body {
    /* 100% = dialog height, 120px = header + footer */
    max-height: calc(100% - 0px) !important;
    overflow-y: scroll;
  }
}
